require('./bootstrap');
import { data } from 'jquery';
import { thru } from 'lodash';
import Vue from 'vue';
import VueProgressBar from 'vue-progressbar'
import VueRouter from 'vue-router'

const options = {
    color: '#1F2454',
    failedColor: '#D60606',
    thickness: '3px',
    transition: {
        speed: '0.2s',
        opacity: '0.6s',
        termination: 300
    },
    autoRevert: true,
    inverse: false
}

Vue.use(VueProgressBar, options)
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
},
{
    path: "/agences",
    name: "agences",
}
]

var router = new VueRouter({
    routes: routes,
    mode: 'history'
})

const app = new Vue({
    el: '#navigation',
    router: router,
    data: {
        show: false,
        dropdown: false,
        dropdown1: false,
        hover: false
    },
    mounted() {
        this.$Progress.finish()
    },
    created() {
        this.initializeLoadingBar();
    },

    methods: {
        closeAllDropdown() {
            this.dropdown = false;
            this.dropdown1 = false;
        },
        openSector() {
            this.dropdown1 = false;
            this.dropdown = !this.dropdown;
        },
        openAgences() {
            this.dropdown = false;
            this.dropdown1 = !this.dropdown1;
        },
        closeModal(event) {
            console.log("ddd");
            if (event.target.parentElement.parentElement)
                event.target.parentElement.parentElement.style.opacity = 0;
        },
        openMenu() {
            this.show = !this.show;
            if (this.show)
                $(this.$refs.navbar).css("display", "block");
            else
                $(this.$refs.navbar).css("display", "none");
        },
        initializeLoadingBar() {
            this.$Progress.start()
            this.$router.beforeEach((to, from, next) => {
                if (to.meta.progress !== undefined) {
                    let meta = to.meta.progress
                    this.$Progress.parseMeta(meta)
                }
                this.$Progress.start()
                next()
            })
            this.$router.afterEach((to, from) => {
                this.$Progress.finish()
            })
        },
    }
});